import React from 'react'
import { css } from '@emotion/core'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
  AccordionItemState,
} from 'react-accessible-accordion'
import Fade from 'react-reveal/Fade'
import PropTypes from 'prop-types'
import { colors, breakpoints } from '@dqp/common/styles/utilities'
import Text from '@dqp/common/components/Text/Text'
import stylesForInnerHTML from '@dqp/common/styles/stylesForInnerHTML'
import { reactRevealDuration } from '#globals/constants'

const styles = {
  container: css`
    .accordion__item {
      user-select: none;
      margin-bottom: 20px;
      border-radius: 4px;
      background: white;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.36);
      overflow: hidden;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .accordion__button {
      position: relative;
      padding: 20px 60px;
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      outline: none;
      position: relative;
      background: white;
    }

    .accordion__panel {
      user-select: text;
      padding: 30px 60px;
      padding-top: 0;
      animation: fadein 0.35s ease-in;
    }
    @keyframes fadein {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 100%;
      }
    }
    background-color: ${colors.greyLightest};
    ${breakpoints({
      paddingTop: [80, 80, 140, 100, 100],
      paddingBottom: [80, 80, 110, 120, 120],
    })};
  `,
  icon: css`
    display: inline-block;
    height: 15px;
    width: 15px;
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-right: 10px;
    .vLine,
    .hLine {
      display: inline-block;
      position: absolute;
      height: 2px;
      width: 100%;
      background-color: ${colors.grey};
    }
    .vLine,
    .hLine {
      top: 50%;
      transform: translateY(-50%);
    }
    .hLine {
      transform: translateY(-50%) rotate(90deg);
      transition: all 0.2s;
    }
    .activeI {
      transform: translateY(-50%) rotate(180deg);
    }
  `,
}
function Accordions({ faqs }) {
  return (
    <section css={styles.container}>
      <Fade clear duration={reactRevealDuration}>
        <div className='container relative'>
          <div className='row justify-content-center'>
            <div className='col-lg-10'>
              <Accordion allowZeroExpanded>
                {faqs.map(faq => (
                  <AccordionItem key={faq.id}>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <AccordionItemState>
                          {({ expanded }) => (
                            <span css={styles.icon}>
                              <span className='vLine' />
                              <span
                                className={`hLine ${
                                  expanded ? 'activeI' : ''
                                }`}
                              />
                            </span>
                          )}
                        </AccordionItemState>
                        <Text
                          weight='bold'
                          color={colors.black}
                          size='large'
                        >
                          {faq.question}
                        </Text>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Text
                        size='medium'
                        color={colors.charcoal}
                        as='div'
                        css={css`
                          ${stylesForInnerHTML};
                          p {
                            margin-bottom: 0;
                          }
                        `}
                        dangerouslySetInnerHTML={{
                          __html: faq.answer,
                        }}
                      />
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </Fade>
    </section>
  )
}

Accordions.propTypes = {
  faqs: PropTypes.array.isRequired,
}

export default Accordions
