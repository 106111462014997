import React from 'react'
import { colors } from '@dqp/common/styles/utilities'
import SEO from '#components/SEO'
import PublicLayout from '#components/PublicLayout'
import FAQ from '#containers/FAQ/FAQ'
import heroimage from '#images/hero/faq_hero.png'

const FaqPage = () => (
  <PublicLayout headerColor={colors.yellow}>
    <SEO
      title='FAQs'
      meta={[{ name: 'theme-color', content: colors.yellow }]}
      image={heroimage}
    />
    <FAQ />
  </PublicLayout>
)

export default FaqPage
