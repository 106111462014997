import React from 'react'
import Wave from '@dqp/common/components/Wave/Wave'
import { colors } from '@dqp/common/styles/utilities'
import { useStaticQuery, graphql } from 'gatsby'
import Hero from './Hero'
import Accordions from './Accordions'

function FAQ() {
  // this is run during build time and is extracted after that.
  const { api } = useStaticQuery(graphql`
    query getFAQs {
      api {
        faqs: getFaqs {
          answer
          id
          question
        }
      }
    }
  `)
  return (
    <>
      <Hero />
      <Wave bg={colors.yellow} fill={colors.greyLightest} />
      <Accordions faqs={api && api.faqs ? api.faqs : []} />
      <Wave
        bg={colors.greyLightest}
        fill={colors.white}
        type='footer'
      />
    </>
  )
}

export default FAQ
