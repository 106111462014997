import React, { useRef, useEffect } from 'react'
import { css } from '@emotion/core'
import { Power0 } from 'gsap'
import {
  spacer,
  colors,
  breakpoints,
} from '@dqp/common/styles/utilities'
import Text from '@dqp/common/components/Text/Text'
import Title from '@dqp/common/components/Title/Title'
import Link from '@dqp/common/components/Link/Link'
import Image from '#components/Image'
import { timelineY } from '#globals/constants'
import ScrollParallax from '#lib/ScrollParallax'
import routes from '#globals/routes'

const styles = {
  contaner: css`
    background-color: ${colors.yellow};
    ${breakpoints({
      paddingTop: [40, 40, 180, 180, 180],
      paddingBottom: [80, 80, 120, 120, 120],
    })};
  `,
  title: css`
    ${spacer.mrB20}
  `,

  bulb: css`
    ${breakpoints({
      left: [null, null, -250, -340, -250],
      bottom: [null, null, -300, -350, -350],
    })};
  `,
  cloud: css`
    ${breakpoints({
      top: [null, null, -135, -130, -130],
      right: [null, null, -170, -230, -160],
    })};
  `,
  confetti: css`
    ${breakpoints({
      top: [null, null, -150, -135, -135],
      left: [null, null, -150, -170, -150],
    })};
  `,
}
function Hero() {
  const ctr = useRef(null)
  const bulb = useRef(null)
  useEffect(() => {
    const bulbOptions = {
      options: {
        SMtriggerElement: ctr.current,
        SMduration: '100%',
        SMtriggerHook: 0.09,
        GSelement: bulb.current,
        GSduration: 2,
      },
      timelineVarsStart: {
        y: timelineY.start,
        x: 0,
        rotation: 0,
        ease: Power0.easeNone,
      },
      timelineVarsEnd: {
        y: timelineY.end,
        x: 0,
        rotation: 0,
        ease: Power0.easeNone,
      },
    }

    const bulbParallax = new ScrollParallax()

    bulbParallax.init(
      bulbOptions.options,
      bulbOptions.timelineVarsStart,
      bulbOptions.timelineVarsEnd,
    )

    return () => {
      bulbParallax.destroyController()
    }
  }, [])
  return (
    <section css={styles.contaner}>
      <div className='relative container text-centers'>
        <div className='row justify-content-center'>
          <div className='col-lg-10 text-center'>
            <Title
              as='h1'
              size='xxxxLarge'
              weight='bold'
              color={colors.black}
              css={styles.title}
            >
              Need a hand? Let us help!
            </Title>
            <Text color={colors.black} size='large' css={styles.text}>
              You can find answers to some frequently asked questions
              below. If you can't find what you're looking for, please
              contact us{' '}
              <Link
                to={routes.contact}
                hoverColor='black'
                borderColor='black'
              >
                here
              </Link>
              .
            </Text>
          </div>
        </div>
        <Image
          css={styles.confetti}
          className='d-none d-md-block'
          alt='confetti'
          image='faq/confetti'
          sizes={{
            lg: {
              width: 237,
            },
            md: {
              width: 200,
            },
          }}
        />
        <Image
          css={styles.cloud}
          className='d-none d-md-block'
          alt='cloud'
          image='faq/cloud'
          sizes={{
            lg: {
              width: 303,
            },
            md: {
              width: 250,
            },
          }}
        />
        <Image
          ref={bulb}
          css={styles.bulb}
          className='d-none d-md-block'
          alt='bulb'
          image='faq/bulb'
          sizes={{
            lg: {
              width: 493,
            },
            md: {
              width: 400,
            },
          }}
        />
      </div>
    </section>
  )
}

export default Hero
